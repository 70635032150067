import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import Stack from "@mui/material/Stack";
import { CSVLink } from "react-csv";
import PropTypes from "prop-types";

import Button from "../components/common/Button";
import Overlay from "../components/common/Overlay";
import QuickMenuRow from "./quickmenu/TableRow";
import { CardContainer, CardHeader } from "./quickmenu/Card";

import { useAsyncFn } from "../hooks/useAsyncFn";
import axios from "../utils/axios";
import { formatDDMMYYYY } from "../utils/timeSince";

const ALLOWED_ON_DASHBOARDS = ["BCG"];
const currentDashboard = process.env.REACT_APP_DASHBOARD_NAME;

const EmailStats = ({ workflowName }) => {
    if (!ALLOWED_ON_DASHBOARDS.includes(currentDashboard)) {
        return null;
    }

    const location = useLocation();
    const [stats, setStats] = useState({});
    const [filterByDate, setFilterByDate] = useState({
        from: new Date(new Date().getFullYear(), 0, 1)
            .toISOString()
            .slice(0, 10),
        to: new Date().toISOString().slice(0, 10),
    });

    const { status, submit } = useAsyncFn(async (payload) => {
        try {
            const response = await axios.post(
                "/workflow/getEmailStats",
                payload
            );
            if (response?.data) {
                setStats(response.data.stats);
            } else {
                throw new Error("invalid response");
            }
        } catch (error) {
            throw new Error("something went wrong!");
        }
    });

    const handleChangeFilterByDate = (event) => {
        const updatedState = {
            ...filterByDate,
            [event.target.name]: event.target.value,
        };
        setFilterByDate(updatedState);
    };

    const fetchEmailStats = () => {
        submit({
            workflowId: location.state.workflowId,
            from: filterByDate.from,
            to: filterByDate.to,
        });
    };

    const isLoading = status === "LOADING";

    const FILENAME = `${workflowName}_${formatDDMMYYYY(
        filterByDate.from
    )}_${formatDDMMYYYY(filterByDate.to)}_emails`;

    const EMAIL_STATS = [
        {
            legendClass: "invited",
            label: "Delivered",
            count: stats.delivered || 0,
            fileName: FILENAME + "_delivered.csv",
            data: stats.deliveredDocuments,
        },
        {
            legendClass: "rejected",
            label: "Bounced",
            count: stats.bounced || 0,
            fileName: FILENAME + "_bounced.csv",
            data: stats.bouncedDocuments,
        },
        {
            legendClass: "shortlisted",
            label: "Deferred",
            count: stats.deferred || 0,
            fileName: FILENAME + "_deferred.csv",
            data: stats.deferredDocuments,
        },
    ];

    useEffect(() => {
        fetchEmailStats();
    }, []);

    return (
        <CardContainer>
            <Overlay show={isLoading}>
                <CardHeader title="Email Stats">
                    <Stack direction="row" spacing={2}>
                        <Stack direction="row" alignItems="center" spacing={1}>
                            <label>Start Date:</label>
                            <input
                                type="date"
                                name="from"
                                value={filterByDate.from}
                                onChange={handleChangeFilterByDate}
                            />
                        </Stack>
                        <Stack direction="row" alignItems="center" spacing={1}>
                            <label>End Date:</label>
                            <input
                                type="date"
                                name="to"
                                value={filterByDate.to}
                                onChange={handleChangeFilterByDate}
                            />
                        </Stack>
                        <Button
                            className="btn-primary"
                            size="small"
                            variant="contained"
                            onClick={fetchEmailStats}
                        >
                            Apply
                        </Button>
                    </Stack>
                </CardHeader>
                <table>
                    <tbody>
                        {EMAIL_STATS.map((stat) => (
                            <QuickMenuRow
                                key={stat.label}
                                legendClass={stat.legendClass}
                                label={stat.label}
                                count={stat.count}
                            >
                                {stat.data?.length ? (
                                    <td className="quickmenu__card-td">
                                        <CSVLink
                                            filename={stat.fileName}
                                            data={stat.data}
                                            target="_blank"
                                        >
                                            <svg
                                                viewBox="0 0 16 16"
                                                fill="none"
                                                xmlns="http://www.w3.org/2000/svg"
                                            >
                                                <path
                                                    d="M4.40895 14C4.10211 14 3.84592 13.8972 3.64036 13.6917C3.43481 13.4861 3.33203 13.2299 3.33203 12.9231V3.07692C3.33203 2.77008 3.43481 2.51389 3.64036 2.30833C3.84592 2.10278 4.10211 2 4.40895 2H9.66537L12.6654 5V12.9231C12.6654 13.2299 12.5626 13.4861 12.357 13.6917C12.1515 13.8972 11.8953 14 11.5885 14H4.40895ZM9.33203 5.33333V2.66667H4.40895C4.30639 2.66667 4.21238 2.7094 4.1269 2.79487C4.04143 2.88034 3.9987 2.97436 3.9987 3.07692V12.9231C3.9987 13.0256 4.04143 13.1197 4.1269 13.2051C4.21238 13.2906 4.30639 13.3333 4.40895 13.3333H11.5885C11.691 13.3333 11.785 13.2906 11.8705 13.2051C11.956 13.1197 11.9987 13.0256 11.9987 12.9231V5.33333H9.33203Z"
                                                    fill="#479bd2"
                                                />
                                                <path
                                                    d="M7.66856 7.43641L8.33523 7.43641L8.33523 10.5659L9.73138 9.16975L10.207 9.64155L8.0019 11.8467L5.79676 9.64155L6.26856 9.16591L7.66856 10.5659L7.66856 7.43641Z"
                                                    fill="#479bd2"
                                                />
                                            </svg>
                                            Download CSV
                                        </CSVLink>
                                    </td>
                                ) : null}
                            </QuickMenuRow>
                        ))}
                    </tbody>
                </table>
            </Overlay>
        </CardContainer>
    );
};

EmailStats.propTypes = {
    workflowName: PropTypes.string.isRequired,
};

export default EmailStats;
